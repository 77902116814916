/*
 Theme Name: Modern 3 column Variety Pack - Oak And Brick
 Version: 1
 Author: Wade Berlin
 Last edited:  Jaspreet Kaur - September 14, 2020
*/

body {
	font-size:1.2rem;
	font-family: Arial, Helvetica, sans-serif, Verdana;
}
h1, h2, h3, h4, h5, h6, .register_help h2,
.menu-title, .subdivision-list-title,
.condominium-list-title, .fplist-title,
.search-title, .column-title, .notice_title,
.sd_addressbar, .social-title,
.comment_title, .mtg_title,
.detail-image-title, .detail-column-title,
.detail-comment-title, .detail-mapview-title,
.detail-streetview-title, .detail-features-title,
.detail-contact-title, .featured_listings_header,
.subhead_agentlist, .detail-social-title, .testimonial_subtitle,
.featured_listings, .page-title, .liked-list-title, .testimonial .testimonial_title,
.blog-title, .agent_listings_header, .agent-testimonial-title, .refine-title, .notice-title, .buyer_agent_comm_title {
	font-size: 1.4rem;
	text-align: center;
	padding: 3px;
	margin-bottom:3px;
	box-sizing: border-box;
	font-weight: bold;
}
.page-title, .search-title {
	margin-bottom: 10px;
}
.detail-features-title {
	margin-bottom: 3px;
}

.buyer_agent_comm_block{
	padding: 0 5px;
}
blockquote{
	display:block;
	background: #fff;
	padding: 15px 20px 15px 45px;
	margin: 0 0 20px;
	position: relative;
}
blockquote::before{
	content: "\201C";
	font-family: Georgia, serif;
	font-size: 6.0rem;
	font-weight: bold;
	position: absolute;
	left: 10px;
	top:5px;
}
blockquote::after{
	/*Reset to make sure*/
	content: "";
}

/*************************************
 *Buttons
 *************************************/
.the_search, .submit_button, .submitbtn, .sb_poll_rate, input[type="submit"], input[type="reset"], input[type="button"],
.button.save-search-button, input.the_search.refine_search_bttn, .fpmorelink.more_listings a, .fpmorelink.more_rentals a,
.save-search a, .save-search a:hover, .page_links_submit {
	color: #fff;
	background: #5D1A00;
	border: 1px solid #5D1A00;
	font-size: 1.2rem;
	font-weight: 700;
	text-align: center;
	height: 30px;
	cursor: pointer;
	border-radius: 5px;
	padding: 0 10px;
}
.fpmorelink.more_listings a,
.fpmorelink.more_rentals a {
	display: inline-block;
	line-height: 30px;
	padding: 0px 5px;
}
.fpmorelink.more_rentals, .fpmorelink.more_listings {
	display: block;
	text-align: center;
	height: 35px;
}
.button.save-search-button {
	display: inline-block;
	text-decoration: none;
	margin: 5px 0;
	padding: 0 10px;
	line-height: 30px;
}
input.the_search.refine_search_bttn {
	color: #5D1A00;
	background: #fff;
	border: 1px solid #fff;
}

/*************************************
 *spec the layout divisions
 *************************************/
.maindiv {
	position:relative;
	width: 99%;
	padding: 5px;
}
.leftbar {
	display: inline-block;
	vertical-align: top;
	width: 15%;
}
.centerbar {
	display: inline-block;
	vertical-align: top;
	width: 69.3%;
	margin-left: .25%;
}
.rightbar {
	display: inline-block;
	vertical-align: top;
	width: 15%;
}
.footer {
	position: relative;
	padding: 10px;
	font-weight: bold;
	text-align: center;
}
.footercopy {
	font-size: 1.1rem;
	margin: 0px auto;
	text-align: center;
	width: 70%;
}

/********************************************
  Mortgage calculator
********************************************/
.mtg_input li,
.mtg_output li {
	padding-bottom: 5px;
}

/********************************************
 * cma request
********************************************/
.cma_form_container {
	margin-bottom: 15px;
	padding: 10px;
}
.cma_form_container input[type="text"],
.cma_form_container input[type="email"],
.cma_form_container input[type="tel"],
.cma_form_container select {
	width: 200px;
}
.cma_form li label {
	font-weight: bold;
}
.cma_additional label {
	display: block;
	margin-bottom: 5px;
}
.cma_form_container .cma_form:last-of-type label {
	width: 190px;
}

/*************************************
 * Page styles
 * pagebody is a wrapper for the
 * display portion of the output.
 *************************************/
.pagebody {
	font-size: 1.4rem;
	padding: 10px;
	overflow: hidden;
}

/*************************************
 * Agent header supports
 * the agent info block function
 *************************************/
.agentheader {
	color:#FFFFFF;
	float: right;
	margin-top: 10px;
	margin-right: 10px;
}
.agentheader img {
	float: right;
}
.agentheader ol {
	float: right;
	text-align: right;
	font-size: 1.4rem;
	margin: 0 5px;
}
.agentname {
	font-size: 1.8rem;
	font-style: italic;
}
.agent_designations {
	font-size: 1.2rem;
}
.tagline {
	font-size: 1.4rem;
	font-style:italic;
}
.agentheader a {
	text-decoration: underline;

}
span.designation {
	display: inline;
}
.agentimage, .brokerlogo{
	height: 125px;
}

/*************************************
 * Center Column styles
 *************************************/
.pageholder {
	margin-top: 15px;
}

/*************************************
 * Breadcrumbs
 *************************************/
.breadcrumb {
	font-weight: bold;
	margin-bottom: 5px;
}
.breadcrumb li {
	display: inline-block;
	list-style-position:outside;
	margin: 5px;
	padding-left: 15px;
	background: url(/templates/template_23/icons/arrow.png) no-repeat;
	background-size: 13px 13px;
	background-position-x: left;
}

/********************************************************
 *   featured properties and Liked listings
 ********************************************************/
.featuredprop, .customer_prop {
	font-weight: bold;
	margin-bottom: 5px;
	padding: 0 4px 4px;
}
.customer_prop {
	background: none !important;
}
.customer_likes {
	padding-bottom: 5px;
}

/*************************************
 + Menu
 *************************************/
.menu {
	text-align: left;
	font-weight: bold;
	margin-bottom: 5px;
}
.menu li {
	margin: 0px 10px;
}
.subdivisionlist {
	font-weight: bold;
	margin-bottom: 5px;
}
.subdivisionlist li {
	margin: 0px 10px;
	line-height: 25px;
}
.subdivisionlist a {
	text-decoration: none;
	display: block;
	border-bottom: 1px dotted #000;
}

/*************************************
 + sidesearch
 *************************************/
#sidesearch {
	text-align: left;
	font-weight: bold;
	margin-bottom: 5px;
}
#sidesearch ol {
	padding: 0 5px;
}
#sidesearch li {
	padding-bottom: 10px;
	overflow: hidden;
}
#sidesearch .the_search {
	margin-top: 10px;
}

/*************************************
 * Notice styles
 *************************************/
.notice {
	font-weight: bold;
	padding: 5px;
}

/*********************************************************
 *  Comment forms for detail page
 *********************************************************/
#commentform, .contactform {
	padding: 5px;
	font-weight: bold;
}

/*************************************
 +Search Forms
 *************************************/
.searchform {
	overflow: hidden;
	padding: 5px;
	box-sizing: border-box;
}
.searchform ol {
	width: 41%;
	margin:15px 5px;
}
.searchform li {
	padding-bottom: 5px;
}
.upper_bttn {
	margin: 10px;
}
.lower_bttn {
	margin-right: 10px
}
.searchform .search_built select:last-child {
	margin-left: 183px;
}
.lower-button-container {
    right: 0;
    width: auto;
    height: auto;
}
.search-form-container .form-check input {
    margin: 6px 0 0 10px;
}

/*************************************
*  Polls and Testimonials
*************************************/
.polllist {
	width: 100%;
}
.testimonial_form {
	border: 1px solid #000;
	padding: 5px;
}

/****************************************
 * Blog Page
 ****************************************/
.blog {
	margin-bottom: 20px;
}
.blog-body {
	padding: 5px;
}
.blog .drop_cap {
	margin-top: 0px;
}

/************************************
* Social Link Styles
*************************************/
.social {
	text-align: center;
	margin: 0px 0px 10px 0px;
	padding-bottom: 10px;
}

/********************************************************
 * Agent List, Agent Page styles
 ********************************************************/
.agentlist {
	text-align: center;
}
.agentimg {
	border: 1px solid #003D6C;
}
.agentlist li {
	display: inline-block;
}
.agentpageimg {
	border: 1px solid #003D6C;
}

/**************************************
 * Account Dashobard Page
 **************************************/
.widget-link.agent-widget-link span:hover, .widget-link.agent-widget-link:hover span {
	text-decoration: underline !important;
}
.customer_likes {
	border: none;
}
.likepage_link {
	font-weight: bold;
}
.pagebody .register_help h2, .pagebody .registration_title {
	background: none;
	text-align: left;
}
.searchform li input[type="text"] {
	margin-left: 3px;
}
.searchform .searchprice input[type="text"] {
	width: 82px;
}
.account_settings.saved_search .searchprice input[type="text"] {
	margin-left: 2px;
}
.agentlist li > a {
	display: inline;
}
.pagination {
	padding: 5px;
}
.pagebody .register_help h2, .pagebody .registration_title {
	border: none;
	color: inherit !important;
}
.account_settings.saved_search input[type="text"] {
	margin-left: 4px;
}
.searchitems h2, .searchitems .notice_title {
	color: inherit;
	border: none;
}
.contact_results {
	padding: 5px;
	box-sizing: border-box;
}

/* search-form-container */
.search-form-container {
	position: relative;
}
.search-form-container ol {
	width: 266px;
	float: left;
	padding: 0px;
	margin: 0 4px;
}
.search-form-container input.the_search.upper_bttn {
	float: right;
}
.search-form-container .searchprice {
	width: 186px;
	margin-left: 4px;
}
.search-form-container .search_price input[type="text"] {
	width: 176px;
}
.search-form-container .search_price .search-form-price-to input[type="text"] {
	width: 162px;
	margin-top: 5px;
}

/* Pagination */
#pagination_container {
	margin-bottom: 20px;
}
#pagination_container span {
	margin: 0 2px;
}
#pagination_container form.page_link,
#pagination_container .results_returns {
	display: inline-block;
	margin: 0 2px;
}

#pagination_container .results_returns {
	margin-right: 5px;
}

#pagination_container  select.page_select,
#pagination_container select.sort_select {
	width: auto;
	padding: 0 5px
}

.landing-page-wrap .landing-image-100 {
	width: 98%;
}
.landing-page-wrap .landing-image-50 {
	width: 48.2%;
	height: 250px;
}
.landing-page-wrap .landing-image-25 {
	width: 23.6%;
	height: 130px;
}
.landing-page-wrap .landing-page-form {
	width: 70%;
}
.landing-form-div2 .landing-form-input {
	height: 25px !important;
}
.landing-page-wrap .landing-page-line {
	border-top: 5px solid #5d1a00;
}
.landing-form-title {
	background-color: #5d1a00;
}
.landing-form-checkbox{
	color:#000000;
}

/*************************************
 * Pagination
 *************************************/
 .results_sort {
	margin-top: 9px;
}
.numeric_pagination {
	font-weight: bold;
	margin: 10px 0;
}
.numeric_pagination ol {
	border-left: 0;
	align-items: center;
}
.numeric_pagination li {
	border-right: 0;
	padding: 0;
	margin-right: 5px;
}
.numeric_pagination li.bold {
	padding: 6px 10px;
	border-radius: 5px;
}
.numeric_pagination a,
.numeric_pagination li.ellipsis {
	padding: 6px 10px;
	text-decoration: none;
	border-radius: 5px;
}

#upper-pagination_container {
	padding-top: 1px;
	margin-left: 5px;
}
#lower-pagination_container,
#pagination_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 10px;
	padding-top: 5px;
}
#lower-pagination_container .page_links_container {
	margin-top: 0;
}
#lower-pagination_container .page_returns_select,
#lower-pagination_container .page_select {
	width: 75px;
	height: 25px;
	line-height: 25px;
	padding-right: 15px;
}
.numeric_pagination .next_container .next_arrow, .numeric_pagination .prev_container .prev_arrow {
	text-indent: -9999px;
	color: #000;
	display: inline-block;
}
.numeric_pagination .prev_container, .numeric_pagination .next_container {
	display: inline-block;
	width: 10px;
	border: 0;
	padding: 6px 0;
}
.numeric_pagination .prev_container, .numeric_pagination .prev_container:hover {
	margin-right: 5px;
}
.numeric_pagination .next_container, .numeric_pagination .next_container:hover {
	margin-left: 0px;
}
.custom-btn {
	border-radius: 4px;
}

/********************************************
 * Private Listings
********************************************/
.customform-data-container .input-radio label {
	margin-top: 0;
}

.widget-edit-form .input-radio div {
	width: 25% !important;
}

.customform-data-container .documents-container {
    width: 97% !important;
}

.widget-edit-form .input-radio legend {
	font-weight: normal !important;
}
.sidesearch-filter-list .home-page-list-group {
	display: none;
	position: absolute !important;
	width: 14%;
	margin-left:0px !important;
}
.sidesearch-filter-list .home-page-list-group .home-page-list-group-item {
	padding: 5px !important;
	font-size: 12px;
	width: 100% !important;
	position: relative !important;
	text-align: left !important;
}
.sidesearch-filter-list .home-page-list-group .home-page-list-group-item:hover {
	background-color: #ddd;	
}
.sidesearch-filter-list .home-page-list-group .home-page-list-group-item a:hover {
	text-decoration:none;
}
