/*
 Theme Name: Modern 3 column Variety Pack - Oak And Brick
 Version: 1
 Author: Wade Berlin
 Last edited: Jaspreet Kaur- September 14, 2020
*/

/*************************************
* Alternate Results Prop display
*************************************/
.alt_property a {
	text-decoration: none;
	font-weight: bold;

}
.alt_property a:hover {
	font-weight: bold;
}
.alt_propimage{
	width: 200px;
	margin: 5px;
}
.alt_propimage img{
	width: 190px;
}
.alt_address {
	margin-right: 10px;
}
.alt_property .right_side {
	width: 680px;
}
.two_column div.right_side{
	width: 660px;
}
.alt_price {
	font-size: 1.6rem;
	font-weight: bold;
	position: relative;
	margin: 8px 0px 0px 0px;
}
.alt_title {
	margin: 0px 0px 4px 0px;
	font-size: 1.3rem;
}

/* ----- */
.alt_details {
	font-size: 1.1rem;
}
.alt_details.alt_col1 {
	width: 35%;
	float: left;
}
.alt_details.alt_col2 {
	width: 40%;
}

/*************************************
 + Refined Search
 *************************************/
.refine_search {
	margin-bottom: 10px;
}
.refine_search h2 {
	margin-top: 0px;
}
.refine_search ol {
	padding: 10px 10px 0px;
}
.refine_search li {
	padding-bottom: 5px;
}
.refine_search li label {
	margin-top: 6px;
}
.refine_search input[type="text"], .refine_search select {
	width: 100%;
}
.refine_price span,
.refine_search .refine-price-from {
	margin: 0;
	display: inline-block;
	width: 47%;
}
.refine_search span.refine-price-to {
	margin-left: 2%;
}

/*************************************
 +Properties
 *************************************/
.searchitems {
	overflow: hidden;
	border: 1px solid #000;
	padding: 5px;
	margin-bottom: 10px;
	display: block;
}
.searchitems h2, .searchitems .notice_title {
	background: none;
	font-size: 1.3rem;
	line-height: 11px;
	font-variant: normal;
	float: left;
	margin: 0;
	padding: 0;
}
.searchitems li {
	float: left;
	margin-left: 5px;
}

.propholder {
	font-weight: bold;
	margin-bottom: 3px;
	padding-bottom: 5px;
}
.propholder .address {
	margin-left: 5px;
}
.sd_addressbar {
	line-height: 22px;
}
.board_image {
	max-height: 100px;
	width: 100px;
}
.propimage {
	width: 230px;
	margin-left: 5px;
	max-height: 300px;
}
.propimage img {
	width: 230px;
	max-height: 300px;
}
.prop {
	margin-bottom: 5px;
	margin-right: 5px;
}

.prop.co_commn {
  width: calc(60% + 5px);
  float: left;
  position: relative;
  left: 240px;
}
.co_commn_detail_wrap.single_col .prop.co_commn {
  width: 100%;
  left: 0;

}
.propfooter {
	text-align: center;
}
.propfooter b {
	font-size: 1.4rem;
}
.property_listing {
	margin-bottom: 5px;
}

.results_sort select.sort_select {
	width: 220px;
}
